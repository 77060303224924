import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EventFullListCardDto } from '@domains/services/event-full/event-full-list/event-full-list-card/event-full-list-card.component';
import { environment } from '@environment';
import { EventFull, EventFullAlgolia } from '@models/wizbii-event-full/wizbii-event-full.model';
import { AlgoliaClientProvider } from '@wizbii-utils/angular/algolia';
import { SearchParamsObject } from 'algoliasearch';
import { Observable, combineLatest, from, iif, map, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventFullWebservice {
  readonly #http = inject(HttpClient);
  readonly #algoliaClientProvider = inject(AlgoliaClientProvider);

  #getClient() {
    return this.#algoliaClientProvider.getClient(environment.algolia.clientName)!;
  }

  searchEvents(
    params?: Pick<
      SearchParamsObject,
      'page' | 'hitsPerPage' | 'facetFilters' | 'aroundLatLng' | 'aroundRadius' | 'filters'
    >
  ): Observable<{
    events: EventFullListCardDto[];
    pagination: { nbHits: number; page: number };
  }> {
    return this.#getClient().pipe(
      switchMap((client) =>
        from(
          client.searchForHits<EventFullAlgolia>({
            requests: [
              {
                indexName: environment.algolia.index.wizbiiEventFull,
                query: '',
                ...params,
              },
            ],
          })
        )
      ),
      map((response) => response.results[0]),
      switchMap(({ hits, nbHits, page }) => {
        const slugsIds = hits.map(({ slug }, index) => `slugIds[${index}]=${slug}`);
        return combineLatest([
          of({ nbHits, page }),
          iif(
            () => nbHits > 0,
            this.#getEventsBySlugIds(slugsIds).pipe(
              map((events) => [...events].toSorted((a, b) => (a.startDate < b.startDate ? -1 : 1)))
            ),
            of([])
          ),
        ]);
      }),
      map(([pagination, events]) => ({ pagination, events: this.#eventsMapper(events) }))
    );
  }

  #getEventsBySlugIds(slugsIds: string[]): Observable<EventFull[]> {
    return this.#http.get<EventFull[]>(`${environment.api.wef}/v1/events?${slugsIds.join('&')}`);
  }

  getEventsTypes(facetFilters: (string | string[])[]): Observable<Record<string, string>> {
    return this.#getClient().pipe(
      switchMap((client) =>
        from(
          client.searchForFacets({
            requests: [
              {
                indexName: environment.algolia.index.wizbiiEventFull,
                type: 'facet',
                facet: 'type.label',
                query: '',
                facetFilters,
              },
            ],
          })
        )
      ),
      map((response) => response.results[0]),
      map((types) =>
        types.facetHits.reduce(
          (acc, { value }) => ({
            ...acc,
            [value]: value,
          }),
          {} as Record<string, string>
        )
      )
    );
  }

  getEvent(eventId: string): Observable<EventFull> {
    return this.#http.get<EventFull>(`${environment.api.wef}/v1/events/${eventId}`);
  }

  #eventsMapper(events: EventFull[]): EventFullListCardDto[] {
    return events.map(
      ({ company, placesLeft, startDate, endDate, title, location, isPhysical, isRemote, slug, source }) => ({
        organizer: company?.name ?? '',
        remainingPlaces: placesLeft,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        title,
        place: location?.city,
        isRemote: Boolean(isRemote),
        isPhysical: Boolean(isPhysical),
        eventSlug: slug,
        source,
      })
    );
  }
}
